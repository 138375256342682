<template>
    <b-container>
        <b-card>
            <b-card-body>
                <b-form-group description="Наименование">
                    <input :disabled="loading" class="form-control" type="text" v-model="storage.name" required>
                </b-form-group>
                <b-table-simple striped :responsive="true">
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>Пользователь</b-th>
                            <b-th>Дата начала ответственности</b-th>
                            <b-th>Дата окончания ответственности</b-th>
                            <b-th>Действия</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-if="storage.responsibleUsers && storage.responsibleUsers.length > 0">
                            <b-tr v-for="responsibleUser in storage.responsibleUsers" :key="makeHash(responsibleUser)">
                                <b-th>{{ responsibleUser.fullname }}</b-th>
                                <b-th>
                                    <date-picker
                                        lang="ru"
                                        type="date"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Дата от"
                                        :disabled="currentEditableUserHash !== makeHash(responsibleUser)"
                                        v-model="responsibleUser.pivot.date_from"
                                        key="responsibleUser.pivot.date_from"
                                        id="responsibleUser.pivot.date_from"
                                    />
                                </b-th>
                                <b-th>
                                    <date-picker
                                        lang="ru"
                                        type="date"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Дата до"
                                        :disabled="currentEditableUserHash !== makeHash(responsibleUser)"
                                        v-model="responsibleUser.pivot.date_to"
                                        key="responsibleUser.pivot.date_to"
                                        id="responsibleUser.pivot.date_to"
                                    />
                                </b-th>
                                <b-th>
                                    <b-button-group>
                                        <b-button variant="primary" @click="editResponsibleUser(responsibleUser)" v-if="currentEditableUserHash !== makeHash(responsibleUser)">Изменить</b-button>
                                        <b-button variant="success" @click="confirmResponsibleChange(responsibleUser)" v-if="currentEditableUserHash === makeHash(responsibleUser)">Подтвердить</b-button>
                                        <b-button variant="danger" @click="deleteResponsibleUser(responsibleUser)">Удалить
                                        </b-button>
                                    </b-button-group>
                                </b-th>
                            </b-tr>
                        </template>
                        <template v-else>
                            <b-tr>
                                <b-th colspan="4">
                                    Пока что ничего нет
                                </b-th>
                            </b-tr>
                        </template>
                        <b-tr>
                            <b-th>
                                <user-input v-model="newResponsibleUser.user"></user-input>
                            </b-th>
                            <b-th>
                                <date-picker
                                    :disabled="!newResponsibleUser.user"
                                    lang="ru"
                                    type="date"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Дата от"
                                    v-model="newResponsibleUser.date_from"
                                />
                            </b-th>
                            <b-th>
                                <date-picker
                                    :disabled="!newResponsibleUser.user"
                                    lang="ru"
                                    type="date"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Дата до"
                                    v-model="newResponsibleUser.date_to"
                                />
                            </b-th>
                            <b-th>
                                <b-button :disabled="!newResponsibleUser.user" @click="add" variant="success">+</b-button>
                            </b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-form-group description="Площадка">
                    <area-select v-model="storage.area_id"/>
                </b-form-group>
                <b-form-group description="Подразделение">
                    <input :disabled="loading" class="form-control" type="text" v-model="storage.name" required>
                </b-form-group>
                <b-form-group description="Если установлено, в акте передачи можно будет указать кол-во, превышающее остаток по складу">
                    <b-checkbox :disabled="loading" v-model="storage.allow_lot_below_zero">Разрешить превышать остаток партии при перемещении</b-checkbox>
                </b-form-group>
                <b-form-group>
                    <div><b-checkbox :disabled="loading" v-model="storage.use_address_storage">Использовать ячейки</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.use_markup_in_production">use_markup_in_production</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.is_manufacturer">Производственный цех</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.waste">Отходы</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.packing">Фасовка</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.material">Сырьевой склад</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.brand_kitchen">Бренд-кухня</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.auto_accept">Автоматически принимать передачи номенклатуры</b-checkbox></div>
                    <div><b-checkbox :disabled="loading" v-model="storage.salary_enabled">Расчет зарплаты</b-checkbox></div>
                </b-form-group>
            </b-card-body>

            <b-card-footer>
                <div class="d-flex justify-content-between">
                    <b-button @click="cancel()" variant="danger">Назад</b-button>
                    <b-button @click="save()" variant="success">Сохранить</b-button>
                </div>
            </b-card-footer>
        </b-card>
    </b-container>

</template>

<script>
import {STORAGE_ENDPOINT} from '@utils/endpoints';
import UserInput          from "@components/_common/UserInput";
import DatePicker         from 'vue2-datepicker';
import cloneDeep          from 'lodash/cloneDeep';
import {AreaSelect}       from '@components';

export default {
    name: 'Form',
    components: {
        UserInput,
        AreaSelect,
        DatePicker,
    },
    data() {
        return {
            goBackLength: -1,
            loading: false,
            storage: {
                name: '',
                use_address_storage: false,
                use_markup_in_production: false,
                is_manufacturer: false,
                waste: false,
                packing: false,
                material: false,
                brand_kitchen: false,
                allow_lot_below_zero: true,
                auto_accept: false,
                salary_enabled: false,
                area_id: null,
            },
            newResponsibleUser: {
                user: null,
                date_from: null,
                date_to: null,
            },
            currentEditableUserPrevState: null,
            currentEditableUserHash: null,
        };
    },
    mounted() {
        this.fetchStorage();
    },
    methods: {
        makeHash(responsibleUser) {
            return responsibleUser.pivot.user_id + '-' + responsibleUser.pivot.date_from;
        },
        confirmResponsibleChange(responsibleUser) {
            this.$http.put(STORAGE_ENDPOINT + `/${this.$route.params.id}/edit-responsible`, {
                responsible_user: this.currentEditableUserPrevState.pivot,
                new_responsible: responsibleUser.pivot
            })
                .then(response => {
                    this.$set(this.storage, 'responsibleUsers', response.data.data)

                    this.currentEditableUserPrevState = null
                    this.currentEditableUserId = null
                })
                .catch(this.$root.responseError)
        },
        editResponsibleUser(responsibleUser) {
            this.currentEditableUserPrevState = cloneDeep(responsibleUser);
            this.currentEditableUserHash = this.makeHash(responsibleUser);
        },
        deleteResponsibleUser(responsibleUser) {
            this.$http.post(STORAGE_ENDPOINT + `/${this.$route.params.id}/delete-responsible`, {
                responsible_user: {...responsibleUser.pivot},
            })
            .then(response => {
                this.$set(this.storage, 'responsibleUsers', response.data.data)
            })
            .catch(this.$root.responseError)
        },
        add() {
            this.$http.post(STORAGE_ENDPOINT + `/${this.$route.params.id}/add-responsible`, {
                responsible_user: {...this.newResponsibleUser, user_id: this.newResponsibleUser.user.id},
            })
            .then(response => {
                this.$set(this.storage, 'responsibleUsers', response.data.data)

                this.newResponsibleUser = {};
            })
                .catch(this.$root.responseError)
        },
        fetchStorage() {
            if (!this.$route.params.id) {
                return;
            }

            this.loading = true;
            this.$http.get(
                `${STORAGE_ENDPOINT}/${this.$route.params.id}`,
                {params: {with: ['responsibleUsers']}}
            )
            .then(response => response.data.data)
            .then(data => {
                this.loading = false;
                this.storage = data;
            })
            .catch(error => {
                this.loading = false;
                this.$root.responseError(error, 'Ошибка!')
            });
        },
        cancel() {
            this.$router.go(this.goBackLength);
        },
        save() {
            if (this.storage.name === '') {
                alert('Укажите название');
                return;
            }

            const responsibleUsers = this.storage.responsibleUsers
                ? this.storage.responsibleUsers.reduce((acc, item) => {
                    acc = [...acc, {
                        user_id: item.id,
                        date_from: item.pivot.date_from,
                        date_to: item.pivot.date_to,
                        storage_id: item.pivot.storage_id,
                    }]

                    return acc
                }, [])
                : []

            this.loading = true;
            this.$http.put(
                `${STORAGE_ENDPOINT}/${this.storage.id}`,
                {...this.storage, responsible_users: responsibleUsers}
            )
            .then(response => {
                this.loading = false;
                this.storage = response.data.data;
                this.storage.responsibleUsers = this.storage.responsible_users;
                this.successCountDown = 5;
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
            })
            .catch(response => {
                this.loading = false;
                this.$root.responseError(response)
            })
            .finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>
